<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-signin-on"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #F2C98A;"
        :style="{
          backgroundImage: `url(${asideBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <a href="#" class="text-center mb-10">
            <img
              src="media/lexicai/logos/logo-white.png"
              class="max-h-70px"
              alt=""
            />
          </a>
          <h3
            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
            style="color: white;"
          >
            {{ $t("AUTH.GENERAL.SUBTITLE_LINE1") }} <br />
            {{ $t("AUTH.GENERAL.SUBTITLE_LINE2") }}
          </h3>
        </div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->

          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_recovery_form"
              @submit.stop.prevent="onSubmitRecovery()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  {{ $t("AUTH.LOGIN.WELCOME") }}
                </h3>
                <span class="text-muted font-weight-bold font-size-h4"
                  >{{ $t("ACCOUNT.CHANGE_PASS") }}
                </span>
              </div>
              <Loader
                v-if="validToken === null"
                v-bind:logo="loaderLogo"
              ></Loader>
              <div v-if="validToken === true">
                <div class="form-group">
                  <label class="font-size-h6 font-weight-bolder text-dark">{{
                    $t("ACCOUNT.NEW_PASS")
                  }}</label>
                  <div
                    id="example-input-group-1"
                    label=""
                    label-for="example-input-1"
                  >
                    <input
                      class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      type="password"
                      name="new_password"
                      ref="new_password"
                      v-model="form.new_password"
                    />
                  </div>
                  <div
                    class="error text-primary"
                    v-if="!$v.form.new_password.required"
                  >
                    {{ $t("MSG.REQUIRED") }}
                  </div>
                  <div
                    class="error text-primary"
                    v-if="!$v.form.new_password.minLength"
                  >
                    {{ $t("MSG.MIN_LEN", { len: 8 }) }}
                  </div>
                </div>
                <div class="form-group">
                  <div class="d-flex justify-content-between mt-n5">
                    <label
                      class="font-size-h6 font-weight-bolder text-dark pt-5"
                      >{{ $t("ACCOUNT.REPEAT_PASS") }}</label
                    >
                  </div>
                  <div
                    id="example-input-group-2"
                    label=""
                    label-for="example-input-2"
                  >
                    <input
                      :type="'password'"
                      class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                      name="verify_password"
                      ref="verify_password"
                      v-model="form.verify_password"
                    />
                    <div
                      class="error text-primary"
                      v-if="!$v.form.verify_password.sameAsPassword"
                    >
                      {{ $t("MSG.SAME_PASS") }}
                    </div>
                  </div>
                </div>
                <!-- <div class="pb-lg-0 pb-5">
                        <vue-recaptcha :sitekey="recaptchaPublic" @verify="onVerify" @expired="onExpired"></vue-recaptcha>
                    </div> -->
                <div class="pb-lg-0 pb-5">
                  <button
                    ref="kt_login_recovery_submit"
                    @click="onSubmitRecovery()"
                    class="btn btn-primary btn-lg my-3 mr-3"
                    :disabled="$v.form.$invalid"
                  >
                    {{ $t("ACTION.CHANGE") }}
                  </button>
                </div>
              </div>
              <div v-if="validToken === false">
                <div role="alert" class="alert fade alert-danger show">
                  <div class="alert-text">
                    El token ingresado no es válido o ha expirado.
                  </div>
                </div>
              </div>
            </form>
          </div>

          <!--end::Signin-->
        </div>
        <!--begin::Content footer-->
        <div
          class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
          <!-- <a href="#" class="text-primary font-weight-bolder font-size-h5"
            >Terms</a
        >
        <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Plans</a
        >
        <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Contact Us</a
        > -->
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import { mapGetters, mapState } from "vuex";
//import VueRecaptcha from 'vue-recaptcha';
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import Loader from "@/view/content/Loader.vue";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  name: "recovery",
  data() {
    return {
      state: "signin",
      resource: "admins/auth",
      // Remove this dummy login info
      validToken: null,
      form: {
        recaptchaToken: "",
        new_password: "",
        verify_password: ""
      },
      token: this.$route.params.token,
      recaptchaPass: false,
      recaptchaPublic: process.env.VUE_APP_RECAPTCHA_PUBLIC
    };
  },
  components: {
    Loader
    //VueRecaptcha
  },
  validations: {
    form: {
      new_password: {
        required,
        minLength: minLength(8)
      },
      verify_password: {
        sameAsPassword: sameAs("new_password")
      }
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser", "layoutConfig"]),
    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },
    asideBg() {
      return `${process.env.BASE_URL}media/lexicai/bg/home_bg.png`;
    },
    asideImg() {
      return `${process.env.BASE_URL}media/lexicai/items/home_geometrics.png`;
    }
  },
  mounted() {
    this.validateToken();
  },
  methods: {
    onVerify(response) {
      console.log(response);
      this.form.recaptchaToken = response;
      this.recaptchaPass = true;
    },
    onExpired(response) {
      console.log(response);
      this.form.recaptchaToken = response;
      this.recaptchaPass = true;
    },
    onSubmitRecovery() {
      // set spinner to submit button
      const submitButton = this.$refs["kt_login_recovery_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      submitButton.disabled = true;

      // send login request
      ApiService.post(`${this.resource}/recovery-confirm`, {
        token: this.token,
        password: this.form.new_password,
        recaptchaToken: this.form.recaptchaToken
      })
        .then(result => {
          Swal.fire({
            title: "",
            text: "Se ha modificado tu contraseña",
            icon: "success",
            heightAuto: false,
            timer: 1500
          });

          console.log(result);
          // submitButton.classList.remove(
          //     "spinner",
          //     "spinner-light",
          //     "spinner-right"
          // );
          // submitButton.disabled = false;

          this.$router.push({ name: "login" });
        })
        .catch(error => {
          Swal.fire({
            title: "",
            text: "Error en el servidor, intente de nuevo.",
            icon: "error",
            position: "center",
            heightAuto: false
          });
          console.log(error);

          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
        });

      // this.fv.on("core.form.invalid", () => {
      //     Swal.fire({
      //         title: "",
      //         text: "Please, provide correct data!",
      //         icon: "error",
      //         confirmButtonClass: "btn btn-secondary",
      //         heightAuto: false
      //     });
      // });
    },

    validateToken() {
      // send login request
      ApiService.get(`${this.resource}/recovery`, this.token)
        .then(result => {
          console.log(result);
          this.validToken = true;
        })
        .catch(error => {
          console.log(error);

          this.validToken = false;
        });

      // this.fv.on("core.form.invalid", () => {
      //     Swal.fire({
      //         title: "",
      //         text: "Please, provide correct data!",
      //         icon: "error",
      //         confirmButtonClass: "btn btn-secondary",
      //         heightAuto: false
      //     });
      // });
    }
  }
};
</script>
