var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-column flex-root" }, [
    _c(
      "div",
      {
        staticClass:
          "login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-signin-on",
        attrs: { id: "kt_login" }
      },
      [
        _c(
          "div",
          {
            staticClass: "login-aside d-flex flex-column flex-row-auto",
            staticStyle: { "background-color": "#F2C98A" },
            style: {
              backgroundImage: "url(" + _vm.asideBg + ")",
              backgroundSize: "cover",
              backgroundPosition: "center"
            }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column-auto flex-column pt-lg-40 pt-15"
              },
              [
                _vm._m(0),
                _c(
                  "h3",
                  {
                    staticClass:
                      "font-weight-bolder text-center font-size-h4 font-size-h1-lg",
                    staticStyle: { color: "white" }
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("AUTH.GENERAL.SUBTITLE_LINE1")) + " "
                    ),
                    _c("br"),
                    _vm._v(
                      " " + _vm._s(_vm.$t("AUTH.GENERAL.SUBTITLE_LINE2")) + " "
                    )
                  ]
                )
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
          },
          [
            _c("div", { staticClass: "d-flex flex-column-fluid flex-center" }, [
              _c("div", { staticClass: "login-form login-signin" }, [
                _c(
                  "form",
                  {
                    staticClass: "form",
                    attrs: {
                      novalidate: "novalidate",
                      id: "kt_login_recovery_form"
                    },
                    on: {
                      submit: function($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.onSubmitRecovery()
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "pb-13 pt-lg-0 pt-5" }, [
                      _c(
                        "h3",
                        {
                          staticClass:
                            "font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("AUTH.LOGIN.WELCOME")) + " "
                          )
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-muted font-weight-bold font-size-h4"
                        },
                        [_vm._v(_vm._s(_vm.$t("ACCOUNT.CHANGE_PASS")) + " ")]
                      )
                    ]),
                    _vm.validToken === null
                      ? _c("Loader", { attrs: { logo: _vm.loaderLogo } })
                      : _vm._e(),
                    _vm.validToken === true
                      ? _c("div", [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass:
                                  "font-size-h6 font-weight-bolder text-dark"
                              },
                              [_vm._v(_vm._s(_vm.$t("ACCOUNT.NEW_PASS")))]
                            ),
                            _c(
                              "div",
                              {
                                attrs: {
                                  id: "example-input-group-1",
                                  label: "",
                                  "label-for": "example-input-1"
                                }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.form.new_password,
                                      expression: "form.new_password"
                                    }
                                  ],
                                  ref: "new_password",
                                  staticClass:
                                    "form-control form-control-solid h-auto py-7 px-6 rounded-lg",
                                  attrs: {
                                    type: "password",
                                    name: "new_password"
                                  },
                                  domProps: { value: _vm.form.new_password },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.form,
                                        "new_password",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]
                            ),
                            !_vm.$v.form.new_password.required
                              ? _c(
                                  "div",
                                  { staticClass: "error text-primary" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("MSG.REQUIRED")) + " "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            !_vm.$v.form.new_password.minLength
                              ? _c(
                                  "div",
                                  { staticClass: "error text-primary" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("MSG.MIN_LEN", { len: 8 })
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]),
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-between mt-n5"
                              },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "font-size-h6 font-weight-bolder text-dark pt-5"
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("ACCOUNT.REPEAT_PASS"))
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                attrs: {
                                  id: "example-input-group-2",
                                  label: "",
                                  "label-for": "example-input-2"
                                }
                              },
                              [
                                "password" === "checkbox"
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.form.verify_password,
                                          expression: "form.verify_password"
                                        }
                                      ],
                                      ref: "verify_password",
                                      staticClass:
                                        "form-control form-control-solid h-auto py-7 px-6 rounded-lg",
                                      attrs: {
                                        name: "verify_password",
                                        type: "checkbox"
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.form.verify_password
                                        )
                                          ? _vm._i(
                                              _vm.form.verify_password,
                                              null
                                            ) > -1
                                          : _vm.form.verify_password
                                      },
                                      on: {
                                        change: function($event) {
                                          var $$a = _vm.form.verify_password,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.form,
                                                  "verify_password",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.form,
                                                  "verify_password",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.form,
                                              "verify_password",
                                              $$c
                                            )
                                          }
                                        }
                                      }
                                    })
                                  : "password" === "radio"
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.form.verify_password,
                                          expression: "form.verify_password"
                                        }
                                      ],
                                      ref: "verify_password",
                                      staticClass:
                                        "form-control form-control-solid h-auto py-7 px-6 rounded-lg",
                                      attrs: {
                                        name: "verify_password",
                                        type: "radio"
                                      },
                                      domProps: {
                                        checked: _vm._q(
                                          _vm.form.verify_password,
                                          null
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.$set(
                                            _vm.form,
                                            "verify_password",
                                            null
                                          )
                                        }
                                      }
                                    })
                                  : _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.form.verify_password,
                                          expression: "form.verify_password"
                                        }
                                      ],
                                      ref: "verify_password",
                                      staticClass:
                                        "form-control form-control-solid h-auto py-7 px-6 rounded-lg",
                                      attrs: {
                                        name: "verify_password",
                                        type: "password"
                                      },
                                      domProps: {
                                        value: _vm.form.verify_password
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.form,
                                            "verify_password",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    }),
                                !_vm.$v.form.verify_password.sameAsPassword
                                  ? _c(
                                      "div",
                                      { staticClass: "error text-primary" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("MSG.SAME_PASS")) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "pb-lg-0 pb-5" }, [
                            _c(
                              "button",
                              {
                                ref: "kt_login_recovery_submit",
                                staticClass: "btn btn-primary btn-lg my-3 mr-3",
                                attrs: { disabled: _vm.$v.form.$invalid },
                                on: {
                                  click: function($event) {
                                    return _vm.onSubmitRecovery()
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("ACTION.CHANGE")) + " "
                                )
                              ]
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm.validToken === false ? _c("div", [_vm._m(1)]) : _vm._e()
                  ],
                  1
                )
              ])
            ]),
            _c("div", {
              staticClass:
                "d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
            })
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "text-center mb-10", attrs: { href: "#" } }, [
      _c("img", {
        staticClass: "max-h-70px",
        attrs: { src: "media/lexicai/logos/logo-white.png", alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "alert fade alert-danger show", attrs: { role: "alert" } },
      [
        _c("div", { staticClass: "alert-text" }, [
          _vm._v(" El token ingresado no es válido o ha expirado. ")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }