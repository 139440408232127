var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-loader page-loader-logo" }, [
    _c("img", { attrs: { alt: "Logo", src: _vm.logo, width: "100" } }),
    _c("div", {
      staticClass: "spinner",
      class: _vm.spinnerClass || "spinner-primary"
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }